<template>
    <div class="personal">
        <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
            <div>个人资料</div>
            <div class="edits" v-if="!show" @click="edits">编辑资料</div>
        </div>
        <div class="content">
            <el-form label-position="left" label-width="90px" :model="form">
                <el-form-item label="头像：">
                    <Uploads @change="getImg" v-if="show == true" :imgUrl="info.avatar" :accept="'image/*'" />
                    <img :src="$commit.getImg(info.avatar)" alt="" v-else class="img">
                </el-form-item>
                <el-form-item label="姓名：">
                    <el-input v-model="form.realname" v-if="show == true" placeholder="请输入您的姓名"></el-input>
                    <div v-else>{{ info.realname }}</div>
                </el-form-item>
                <el-form-item label="昵称：">
                    <el-input v-model="form.nickname" v-if="show == true" placeholder="请输入您的昵称"></el-input>
                    <div v-else>{{ info.nickname }}</div>
                </el-form-item>
                <el-form-item label="性别：">
                    <el-radio-group v-model="form.gender" v-if="show == true" text-color="#CC0000">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                    <div v-else>{{ info.gender == 1 ? '男' : info.gender == 2 ? '女' : '' }}</div>
                </el-form-item>
                <el-form-item label="出生日期：">
                    <el-date-picker v-model="form.birthday" value-format="yyyy-MM-dd" v-if="show == true" type="date"
                        placeholder="选择出生日期"></el-date-picker>
                    <div v-else><span v-if="info.birthday">{{ info.birthday }}</span></div>
                </el-form-item>
                <el-form-item label="现居住地：" v-if="show == true">
                    <div class="fx">
                        <el-select v-model="form.province_id" placeholder="请选择省份"
                            @change="form.city_id = '', form.area_id = '', getcity(form.province_id)">
                            <el-option v-for="item in province" :key="item.id" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                        <el-select v-model="form.city_id" placeholder="请选择城市"
                            @change="form.area_id = '', getdistrict(form.city_id)">
                            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                        <el-select v-model="form.area_id" placeholder="请选择地区">
                            <el-option v-for="item in district" :key="item.id" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="详细地址：">
                    <el-input v-model="form.address" v-if="show == true" placeholder="请输入您的详细地址"></el-input>
                    <div v-if="show == false">
                        {{ info.province ? info.province.name + info.city.name + info.area.name + info.address : info.address }}</div>
                </el-form-item>
                <el-form-item label="个人简介：">
                    <el-input type="textarea" v-model="form.intro" :rows="5" resize="none" v-if="show == true"
                        placeholder="请输入您的个人简介"></el-input>
                    <div v-else>{{ info.intro }}</div>
                </el-form-item>
            </el-form>
            <div class="bottom" v-if="show">
                <div class="btn cursor" @click="save">保存</div>
                <div class="cursor" @click="show = false">取消修改</div>
            </div>
        </div>
    </div>
</template>

<script>
import { users_edit } from '@/api/user'
import { areas } from '@/api/commit'
export default {
    data() {
        return {
            form: {},
            show: false,
            province: [],
            city: [],
            district: [],
        };
    },
    props: {
        info: {
            default: null,
            type: Object
        }
    },
    created() {
        this.getprovince()
    },
    mounted() {

    },
    methods: {
        getprovince() {
            areas(0).then(res => {
                if (res.code == 200) {
                    this.province = res.data
                }
            })
        },
        getcity(e) {
            areas(e).then(res => {
                if (res.code == 200) {
                    this.city = res.data
                }
            })
        },
        getdistrict(e) {
            areas(e).then(res => {
                if (res.code == 200) {
                    this.district = res.data
                }
            })
        },
        edits() {
            this.show = true
            this.form = JSON.parse(JSON.stringify(this.info))
            if (this.form.province_id == 0) {
                this.form.province_id = ''
                this.form.city_id = ''
                this.form.area_id = ''
            }
            if (this.form.province_id != 0) {
                this.getcity(this.form.province_id)
            }
            if (this.form.city_id != 0) {
                this.getdistrict(this.form.city_id)
            }
        },
        getImg(res) {
            this.info.avatar = res
            this.form.avatar = res
        },
        save() {
            users_edit(this.form).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.$emit('getInfo', res)
                    this.show = false
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.personal {
    .edits {
        color: #CC0000;
        cursor: pointer;
    }

    .content {
        padding: 40px;

        .el-input {
            width: 300px;
        }

        .img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }

        .bottom {
            text-align: center;
            color: #999;

            .btn {
                margin: 10px auto;
                color: #fff;
                width: 240px;
                height: 48px;
                text-align: center;
                line-height: 48px;
                border-radius: 25px;
                background: #CC0000;
            }
        }
    }

    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
    }

    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        border-radius: 50%;
    }

    ::v-deep .avatar {
        width: 80px;
        height: 80px;
        display: block;
        border-radius: 50%;
    }
}</style>
